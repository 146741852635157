export function useSignOut () {
  const supabase = useSupabaseClient()

  const localePath = useLocalePath()

  const { $clientPosthog, $clearQueryClient } = useNuxtApp()

  const signOut = async () => {
    $clearQueryClient()
    await supabase.auth.signOut()
    $clientPosthog?.reset()
    navigateTo(localePath('sign-in'))
  }

  return { signOut }
}
